import styled from "styled-components";

export const Container = styled.div`
  margin-top: 124px;

  .row {
    width: 90%;
    margin: auto;
  }

  h1 {
    text-align: center;
    margin-bottom: 50px;
    color: #0A69C5;
    text-transform: uppercase;
    font-size: 32px;
  }


  .button {
    text-align: center;
    margin-top: 100px;
    margin-bottom: 100px;
    font-style: italic;
    font-weight: bold;
    padding: 20px 40px;
    background-color: blue;
    width: max-content;
    color: white;
    cursor: pointer;
    border-radius: 12px;
  }

`